import { Link as GatsbyLink } from 'gatsby';
import {
  Container,
  Divider,
  Flex,
  IconButton,
  Link,
  ThemeUIProvider,
} from 'theme-ui';
import theme from '../theme';
import ThemedLink from './Link';
import Logo from './icons/Logo';

interface LayoutProps {
  children: React.ReactNode;
  withFooterDivider?: boolean;
}

const Layout = ({ children, withFooterDivider }: LayoutProps) => {
  return (
    <ThemeUIProvider theme={theme}>
      <div
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <header>
          <Container my={2} sx={{ display: 'grid', placeItems: 'center' }}>
            <IconButton
              aria-label="home"
              as={GatsbyLink}
              // @ts-ignore
              to="/"
              sx={{
                width: 10,
                height: 10,
                mx: 'auto',
                svg: { width: '100%', height: '100%' },
              }}
            >
              <Logo />
            </IconButton>
          </Container>
          <Divider mt={0} />
        </header>

        <main sx={{ flexGrow: 1 }}>{children}</main>

        <footer>
          {withFooterDivider && <Divider />}
          <Container
            sx={{
              my: 8,
              display: 'flex',
              flexDirection: ['column', null, 'row'],
              alignItems: 'center',
              gap: 2,
            }}
          >
            <span>
              &copy;&nbsp;{new Date().getFullYear()}&nbsp;freddydumont
            </span>
            <span sx={{ display: ['none', null, 'inline'] }}>&sdot;</span>
            <Flex sx={{ gap: 2 }}>
              <Link href="https://github.com/freddydumont">GitHub</Link>
              <span>&sdot;</span>
              <Link href="https://www.linkedin.com/in/freddydumont/">
                LinkedIn
              </Link>
              <span>&sdot;</span>
              <Link href="mailto:hello@freddydumont.com">Email</Link>
              <span>&sdot;</span>
              <ThemedLink to="/rss.xml">RSS</ThemedLink>
            </Flex>
          </Container>
        </footer>
      </div>
    </ThemeUIProvider>
  );
};

export default Layout;
