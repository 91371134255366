import { graphql, useStaticQuery } from 'gatsby';
import { Helmet, HelmetProps } from 'react-helmet';
import { useThemeUI } from 'theme-ui';

interface SeoProps {
  description?: string;
  lang?: string;
  meta?: HelmetProps['meta'];
  title?: string;
  /** Is the page a blog post? */
  isPost?: boolean;
  /** Page path name */
  pathname?: string;
}

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
const Seo = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
  isPost = false,
  pathname,
}: SeoProps) => {
  const { site } = useStaticQuery<Queries.SeoQuery>(graphql`
    query Seo {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);

  const { colorMode } = useThemeUI();

  const baseUrl = site?.siteMetadata?.siteUrl;
  const fullUrl = `${baseUrl}${pathname || '/'}`;

  const socialCard = isPost
    ? `${fullUrl}-social-card.png`
    : `${baseUrl}/social-card.png`;

  const metaDescription = description || site?.siteMetadata?.description;
  const defaultTitle = site?.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      defaultTitle={defaultTitle}
      meta={[
        {
          name: 'theme-color',
          content: colorMode,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: fullUrl,
        },
        {
          property: 'og:image',
          content: socialCard,
        },
        {
          name: `twitter:card`,
          content: isPost ? 'summary_large_image' : 'summary',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ]}
    >
      <script>
        {`if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('theme-ui-dark');
        } else {
          document.documentElement.classList.add('theme-ui-__default');
        }
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .addEventListener('change', (event) => {
            if (event.matches) {
              //dark mode
              document.documentElement.classList.remove('theme-ui-__default');
              document.documentElement.classList.add('theme-ui-dark');
            } else {
              //light mode
              document.documentElement.classList.remove('theme-ui-dark');
              document.documentElement.classList.add('theme-ui-__default');
            }
          });`}
      </script>
    </Helmet>
  );
};

export default Seo;
