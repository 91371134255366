import { makeTheme } from '@theme-ui/css/utils';
import colors from 'tailwindcss/colors';

const heading = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

const tableBorders = {
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: 'code-background',
};

const tailwindSpacing = {
  px: '1px',
  0: '0px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
};

const theme = makeTheme({
  config: {
    useLocalStorage: false,
    useColorSchemeMediaQuery: 'system',
  },
  breakpoints: [400, 640, 768, 1024].map((n) => n + 'px'),
  space: tailwindSpacing,
  sizes: {
    ...tailwindSpacing,
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
    screenHeight: '100vh',
    screenWidth: '100vw',
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [
    0.75, 0.0875, 1, 1.125, 1.25, 1.5, 1.875, 2.25, 3, 3.75, 4.5, 6, 8,
  ].map((n) => n + 'rem'),
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: colors.stone[900],
    light: colors.stone[600],
    background: colors.neutral[50],
    link: colors.indigo[600],
    accent: colors.indigo[200],
    // code block colors match the theme in gatsby-remark-vscode options
    'code-background': '#EBEEF5',
    'code-color': '#5D5D5F',
    gradient: `linear-gradient(
      90deg,
      hsl(0deg 0% 98%) 0%,
      hsl(344deg 0% 91%) 10%,
      hsl(344deg 0% 84%) 20%,
      hsl(344deg 0% 77%) 30%,
      hsl(344deg 0% 71%) 40%,
      hsl(0deg 0% 64%) 50%,
      hsl(344deg 0% 71%) 60%,
      hsl(344deg 0% 77%) 70%,
      hsl(344deg 0% 84%) 80%,
      hsl(344deg 0% 91%) 90%,
      hsl(0deg 0% 98%) 100%
    )`,
    modes: {
      dark: {
        text: colors.neutral[100],
        light: colors.neutral[400],
        background: colors.neutral[900],
        link: colors.blue[400],
        accent: colors.blue[900],
        'code-background': '#282c34',
        'code-color': '#9DA5B3',
        gradient: `linear-gradient(
          90deg,
          hsl(0deg 0% 9%) 0%,
          hsl(344deg 0% 19%) 10%,
          hsl(344deg 0% 29%) 20%,
          hsl(344deg 0% 40%) 30%,
          hsl(344deg 0% 52%) 40%,
          hsl(0deg 0% 64%) 50%,
          hsl(344deg 0% 52%) 60%,
          hsl(344deg 0% 40%) 70%,
          hsl(344deg 0% 29%) 80%,
          hsl(344deg 0% 19%) 90%,
          hsl(0deg 0% 9%) 100%
        )`,
      },
    },
  },
  /**
  |--------------------------------------------------
  | COMPONENTS
  |--------------------------------------------------
  */
  layout: {
    container: {
      px: [3, 4, 6, 8, 16],
      maxWidth: (t) => t.breakpoints?.[2],
    },
  },
  /**
  |--------------------------------------------------
  | BASE_STYLES
  |--------------------------------------------------
  */
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: { ...heading, fontSize: '1.75rem' },
    h2: { ...heading, mt: 8 },
    h3: { ...heading, mt: 6 },
    h4: { ...heading },
    h5: { ...heading },
    h6: { ...heading },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      '.gatsby-resp-image-wrapper': {
        my: 8,
      },
    },
    li: {
      mb: 1,
    },
    a: {
      color: 'link',
      ':hover, :focus': {
        textDecoration: 'none',
        backgroundColor: 'link',
        color: 'background',
      },
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      my: 8,
      code: {
        // remove inline code blocks and default padding
        padding: 'unset',
        '--grvsc-padding-h': '1rem',
        // inherit vscode plugin colors
        color: 'inherit',
        backgroundColor: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
      pt: 0.5,
      px: 1,
      color: 'code-color',
      backgroundColor: 'code-background',
    },
    blockquote: {
      my: 8,
      borderLeft: (t) => `${t.sizes?.[1]} solid ${t.colors?.accent}`,
      pl: 5,
      ml: 4,
      cite: {
        display: 'block',
        mt: 2,
        pl: 2,
        '&:before': {
          content: '"– "',
        },
      },
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      my: 8,
    },
    th: {
      textAlign: 'left',
      p: 2,
      backgroundColor: 'code-background',
      ...tableBorders,
    },
    td: {
      textAlign: 'left',
      p: 2,
      ...tableBorders,
    },
    img: {
      maxWidth: '100%',
      // fix for the white box shadow on images with transparent bg
      boxShadow: (t) =>
        `${t.colors?.background} 0px 0px 0px 400px inset !important`,
    },
    hr: {
      my: 8,
      maxWidth: [null, null, null, '900px', '1024px'],
      mx: 'auto',
      border: 0,
      height: 'px',
      backgroundImage: (t) => t.colors?.gradient,
    },
  },
});

export default theme;
